import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_teaching.svg';

function ServicesTrainingPage() {

    return <ContentPage>
        <h1>Training</h1>
        <p>To get the best from any application, the users need to understand how to use it. Our applications are
            designed to be intuitive and consistent but, even then, users often need some guidance to become experts 
            with it.</p>
        <PageImage image={image} alt='Trainer' leftAlign={true} />
        <p>We can develop training materials and programmes for your application, including:</p>
        <ul>
            <li>User manuals – integrated as help in the application or as separate documents.</li>
            <li>Computer-based training.</li>
            <li>Classroom training - delivered in-person or remotely.</li>
        </ul>

    </ContentPage>

}

export default ServicesTrainingPage;