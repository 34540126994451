import React from "react";

function PageImage({
    image,      // image object
    alt,        // alt text
    leftAlign,  // bool show on left
    ...props
}) {

    const classSide = leftAlign ? 'pull-left m-r-15' : 'pull-right m-l-15';
    const imgClass = `width-half m-t-5 m-b-5 ${classSide}`;

    return <img src={image} alt={alt} className={imgClass} />
}

export default PageImage;